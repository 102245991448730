import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap';
import Cookies from "universal-cookie";

import ImgPopup from "../assets/images/popup_250327.jpg";

const _stamp = 'popupClose';
const _dueTo = '2025-04-10';

export default function Popup() {

  const cookies = new Cookies();
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    console.log(`showPopup ${showPopup}`)
    
    console.log(!!cookies.get(_stamp));
    if (moment().isAfter(moment(_dueTo)) || !!cookies.get(_stamp)) {
      setShowPopup(false);
    } else {
      setShowPopup(true);
    }

  },[]);

  const disableOneDay = () => {
    cookies.set(_stamp, true, { expires: new Date(moment().add(1, 'd').format()) });
    setShowPopup(false)
  }

  const closePopup = () => {
    setShowPopup(false)
  }

  if(showPopup) {
      // return (
      //   <div className="popup-frame">
      //     <div className="popup-body">
      //       <p>
      //         룰루플레이TV 새 단장을 하게 되었습니다.
      //         <br />
      //         일정시간 이용이 불가하여 사전 안내 드립니다.
      //       </p>
      //       <div style={{
      //           background: '#222', padding: 16, borderRadius: 4
      //       }}>[작업일시]<br/>2023-03-24 (금) <br/>
      //       오전9시 ~ 오후5시
      //       <br/>
      //       <br/>
      //       [작업내용]<br/>룰루플레이 TV 홈페이지 리뉴얼</div>
      //     </div>
      //     <div className="d-flex justify-content-between">
      //       <Button className="disable" onClick={disableOneDay}>
      //         오늘하루 보지않기
      //       </Button>
      //       <Button className="close" onClick={closePopup}>
      //         닫기
      //       </Button>
      //     </div>
      //   </div>
      // );
      return (
        <div className="popup-image-frame">
          <div className="popup-body">
            <a href="https://onesto.re/0000781084"><img src={ImgPopup} alt="popup" width="100%" /></a>
          </div>
          <div className="d-flex justify-content-between">
            <Button className="disable" onClick={disableOneDay}>
              오늘하루 보지않기
            </Button>
            <Button className="close" onClick={closePopup}>
              닫기
            </Button>
          </div>
        </div>
      );
  }
}
